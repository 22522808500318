<template>
  <div id="featurebrowsecompnent">
    <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
    <div id="speciesselection" style="margin-top: 20px">
      <el-radio-group v-model="species">
        <el-radio-button label="All">All</el-radio-button>
        <el-radio-button label="trae">Wheat</el-radio-button>
        <el-radio-button label="zema">Maize</el-radio-button>
        <el-radio-button label="orsa">Rice</el-radio-button>
        <el-radio-button label="lyes">Tomato</el-radio-button>
      </el-radio-group>
    </div>

    <el-container>
      <el-main>
        <el-table :data="featureData2" style="width: 100%" @row-click="detailSearch" @header-click="sortTable">
          <el-table-column label="Feature ID" width="180">
            <template #default="scope">
              <!-- <p>{{ scope.row.featureID }}</p> -->
              <a :href="$store.state.frontbaseURL+'featuredetail/'+scope.row.featureID">{{ scope.row.featureID
                }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="species" width="100" label="Species">
            <template #default="scope">
              <div>{{ this.$store.state.speciesDict[scope.row.species] }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="rt" width="100" label="RT"></el-table-column>
          <el-table-column prop="q1" width="100" label="Q1"></el-table-column>
          <el-table-column prop="mode" width="120" label="Mode"></el-table-column>
          <!-- <el-table-column prop="ionizationModel" width="100" label="Ion"></el-table-column> -->
          <!-- <el-table-column prop="metabolite" width="170">
            <template #header>
              <div>Article</div>
              <div>annotation</div>
            </template>
          </el-table-column> -->
          <el-table-column prop="identificationLevel" width="160">
            <template #header>
              <div>Identification</div>
              <div>level</div>
            </template>
          </el-table-column>
          <el-table-column prop="name" width="300">
            <template #header>
              <div>Article</div>
              <div>annotation</div>
              <div>name</div>
            </template>
          </el-table-column>
          <el-table-column prop="metabolite2" label="Annotation" width="300"></el-table-column>
          <el-table-column prop="alignScore" width="160">
            <template #header>
              <div>Annotation</div>
              <div>score</div>
            </template>
          </el-table-column>
          <el-table-column prop="name2" label="Annotation name" width="200"></el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <div style="display: flex; justify-content: center">
      <el-pagination background layout="sizes, prev, pager, next, jumper" :total="featureData.length"
        v-model:current-page="searchItem.page" v-model:page-size="searchItem.size" :page-sizes="[10,20,50]">
      </el-pagination>
    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import { ElLoading } from 'element-plus'
  import request from '@/network/request'
  import $ from 'jquery'
  import ChemDoodle from "../../mymodules/ChemDoodleWeb-9.2.0/install/ChemDoodleWeb.js"
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  let canvas
  let context1
  export default {
    name: 'FeatureBrowseCompnent',
    data () {
      return {
        species: 'All',
        searchItem: {
          page: 1,
          size: 10,
        },
        paginationArg: {

        },
        featureData: "",
        featureData2: "",
        featureDataOrignal: "",
        head: "Requesting !",
        count: 0,
        orderItem: "",
        orderTable: {
          "featureID": true,
          "species": true,
          "articleID": true,
          "plotID": true,
          "rt": true,
          "q1": true,
          "mode": true,
          "ionizationModel": true,
          "identificationLevel": true,
          "metabolite": true,
          "name": true,
          "metabolite2": true,
          "msID": true,
          "msType": true,
          "msMode": true,
          "alignScore": true,
          "name2": true
        },
      }
    },
    methods: {
      twoDecimal (valT) {
        return varT.toFixed(2)
      },
      test () {
        this.count += 1
      },
      requestData () {
        const loading = ElLoading.service({
          lock: true,
          text: 'Loading',
          background: 'rgba(0, 0, 0, 0.7)',
        });
        request({
          url: 'feature/list/',
          params: this.params,
        }).then(res => {
          this.featureData = res
          this.featureDataOrignal = res
          let size = this.searchItem.size
          let page = this.searchItem.page
          this.featureData2 = res.slice(page * size - size, page * size)
          this.head = "Feature List"
          loading.close()
        }).catch(err => {
          console.log(err);
        });
      },
      detailSearch (row, column, event) {



        this.$router.push({ name: 'FeatureDetail', params: { format: 'json', searchItem: row.featureID } })
        // this.$router.push({ name: 'MetaboliteDetail', params: { format: 'json', searchItem: metabolite } })
      },
      forwardSort (a, b) {
        return a[this.orderItem] - b[this.orderItem]
      },
      backforwarkSort (a, b) {
        return b[this.orderItem] - a[this.orderItem]
      },
      forwardSortStr (a, b) {
        if (a[this.orderItem] && b[this.orderItem]) {
          return a[this.orderItem].localeCompare(b[this.orderItem])
        } else if (!a[this.orderItem]) {
          return -1
        } else {
          return 1
        }
      },
      backforwarkSortStr (a, b) {
        if (a[this.orderItem] && b[this.orderItem]) {
          return b[this.orderItem].localeCompare(a[this.orderItem])
        } else if (!a[this.orderItem]) {
          return 1
        } else {
          return -1
        }
      },
      sortTable (column, event) {






        if (column.property) {
          this.orderItem = column.property
          if (this.orderItem == "rt" || this.orderItem == "q1" || this.orderItem == "alignScore") {
            if (this.orderTable[this.orderItem]) {
              this.featureData = this.featureData.sort(this.forwardSort)
              this.orderTable[this.orderItem] = false
            } else {
              this.featureData = this.featureData.sort(this.backforwarkSort)
              this.orderTable[this.orderItem] = true
            }
          } else {
            if (this.orderTable[this.orderItem]) {
              this.featureData = this.featureData.sort(this.forwardSortStr)
              this.orderTable[this.orderItem] = false
            } else {
              this.featureData = this.featureData.sort(this.backforwarkSortStr)
              this.orderTable[this.orderItem] = true
            }
          }
        } else {
          this.orderItem = "featureID"
          if (this.orderTable[this.orderItem]) {
            this.featureData = this.featureData.sort(this.forwardSortStr)
            this.orderTable[this.orderItem] = false
          } else {
            this.featureData = this.featureData.sort(this.backforwarkSortStr)
            this.orderTable[this.orderItem] = true
          }
        }
        let size = this.searchItem.size
        let page = this.searchItem.page
        this.featureData2 = this.featureData.slice(page * size - size, page * size)
      },
    },
    components: {
      MetaboliteBrowseCompnentSearch
    },
    created () {
      this.requestData()
    },
    mounted () {
    },
    updated () {

    },
    // 检测params.searchItem的编号
    watch: {
      species: {
        handler (newName, oldName) {
          if (newName == "All") {
            this.featureData = this.featureDataOrignal
            let size = this.searchItem.size
            let page = this.searchItem.page

            this.featureData2 = this.featureData.slice(page * size - size, page * size)
          } else {
            this.featureData = this.featureDataOrignal.filter(item => item.species == newName)
            let size = this.searchItem.size
            let page = this.searchItem.page

            this.featureData2 = this.featureData.slice(page * size - size, page * size)
          }
        },
        immediate: true,
        deep: true,
      },
      searchItem: {
        handler (newName, oldName) {
          let size = newName.size
          let page = newName.page
          this.featureData2 = this.featureData.slice(page * size - size, page * size)
        },
        immediate: true,
        deep: true,
      },
    }
  }
</script>

<style scoped lang="scss">
  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
    /*    max-height: 1000px;
    overflow-y: scroll;*/
  }

  .el-select .el-input {
    width: 130px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
    font-size: 20px;
  }

  .el-table .cell {
    word-break: break-word;
    color: rgb(247, 4, 4);
  }


  #speciesselection {
    position: relative;
    top: 0px;
    left: 20px;
    margin-bottom: 10px;
  }
</style>