<template>
  <div id="featurebrowse">
    <MyheadCompnent></MyheadCompnent>
    <FeatureBrowseCompnent></FeatureBrowseCompnent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
import FeatureBrowseCompnent from '@/components/Browse/FeatureBrowseCompnent.vue'
import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
export default {
  name: 'FeatureBrowse',
  data () {
    return {

    }
  },
  components: {
    FeatureBrowseCompnent,
    MyheadCompnent,
    MyfooterCompnent,
  },
}
</script>

<style scoped lang="scss">
</style>